body {
  margin-bottom: 10%;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-image: url("/background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

button {
  outline: none;
  border: none;
  border-radius: 5px;
}

.trimmed:first-child {
  margin-left: 0;
}

.trimmed:last-child {
  margin-right: 0;
}
